import {createStore} from 'vuex'
//import Vuex from 'vuex'

// https://cp.sprinthost.ru/ воити через google account

export default createStore({
    state: {
        token: localStorage.getItem('token') || null,
        userName: localStorage.getItem('name') || 'n/a',
        role_id: localStorage.getItem('role_id') || 0,
        user_id : localStorage.getItem('user_id') || 0,
       // url_image: "http://orders/image/"
        //url_image: "http://f0846778.xsph.ru/Orders/image/"
        url_image: "https://sshoreca.kz/Orders/image/"
    },
    mutations: {
        set_token(state, payload){
            this.state.token = payload
            localStorage.setItem('token', payload)
        },
        set_role_id(state, payload)
        {
            this.state.role_id = payload
            localStorage.setItem('role_id', payload)
        },
        set_user(state, payload)
        {
            this.state.userName = payload.user_name
            localStorage.setItem('name', payload.user_name)
            this.state.user_id = payload.user_id
            localStorage.setItem('user_id', payload.user_id)
        },
        logout(){
            this.token = null
            localStorage.removeItem('token')
        },
        increment(state) {
            state.count ++
        }
    },
    getters: {
        get_user_id(state){
            return state.user_id
        },
        get_token(state){
            return state.token
        },
        get_role_id(state)
        {
            return state.role_id
        },
        is_authentification(_, getters)
        {
            return !!getters.get_token
        },
        get_url_image(state)
        {
            return state.url_image
        },
        get_user_name(state)
        {
            return '№ '+state.user_id+' ( '+state.userName+' )'
        }

    },
    actions: {
        get_nomenclature()
        {
            let request_data = new FormData()

            this.$http.post('API/get_nomenclature', request_data).then(response => {

                return response.data.data
            })
        }
    }
})
