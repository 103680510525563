<template>
  <div id="app">

    <w-dialog
            v-model="dialog.delete_dialog"
            title="Удаление элемента"
            persistent
            :width="550">
      Вы уверены что хотите удалить этот элемент.
      <template #actions>
        <div class="spacer" />
        <w-button
                class="mr2"
                @click="dialog.delete_dialog = false"
                bg-color="error">
          отмена
        </w-button>
        <w-button
                @click="set_delete()"
                bg-color="success">
          удалить
        </w-button>
      </template>
    </w-dialog>

    <router-link to="/orderclient">   <w-button class="ma1" bg-color="info" lg>Редактировать</w-button></router-link>
<w-button class="ma1" bg-color="success" lg @click="get_report">сохранить в Excel</w-button>
    <w-button v-print="'#printMe'" lg>Печать</w-button>


    <vue-date-picker
            v-model="this.sel_date"
            range multi-calendars
            locale="ru"
            select-text="Выбрать"
            cancel-text="Отмена"
            @update:model-value="this.change_date"
    >

    </vue-date-picker>

    <div>

<div id="printMe" >
        <w-alert info>Дата/Время отбора : {{this.sel_a_date}}</w-alert>

        <w-table
            :headers="table.headers"
            :items="table.items"
            fixed-headers
            fixed-footer
            :loading="table.loading"
        >
          <template #item-cell="{label, header, item}">
            <w-image v-if="header.key == 'foto'"
                     class="mr5"
                     :src="this.$store.getters.get_url_image + label"
                     :width="64"
                     :height="64">
            </w-image>

            <span v-else-if="header.key == 'action'">
                        <w-button v-if="this.tabs_select.id!==0" class="ma1" bg-color="error" icon="mdi mdi-delete-forever" @click="set_delete_dialog(item)"></w-button>
                    </span>

            <span v-else  >
                           {{label}}
                        </span>

          </template>

          <template #loading>
            Загрузка.....
          </template>
          <template #no-data>
            Нету данных
          </template>




        </w-table>
</div>

    </div>


  </div>
</template>

<script>

import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'


export default {
  name: "MyOrder",
    components: {VueDatePicker},
    mounted: function (){
    this.get_orders(this.$store.getters['get_user_id'])
  },
  data: () => {
    return ({
      dialog: { delete_dialog: false },
      tabs_select: {title: "Все", content: "", id: 0, new: 0},
        sel_date : [new Date(), new Date()],
        sel_a_date: new Date(),
      state : {
        date: new Date(2016, 9,  16)
      },
      select_client_id : 0,
      tabs: [],
      table: {
        loading: false,
        headers: [
          { label: 'Фото', key: 'foto', width: 4 },
          { label: 'Найменование', key: 'name', width: 10 },
          { label: 'ед.изм', key: 'unit', width: 4 },
          { label: 'количество', key: 'ammount', width: 4 }

        ],
        items: [ ]
      }
    })
  },
  methods:
      {

        get_report()
        {

          var date_st = new Date(this.sel_date[0]);
          var date_en = new Date(this.sel_date[1]);

          let f_dateSt = ("0" +date_st.getDate()).substr(-2)  + '.'
              + ("0" +(date_st.getMonth()+1)).substr(-2) +
              '.'+ date_st.getFullYear()+' 00:00:00';

          let f_dateEn= ("0" +date_en.getDate()).substr(-2)  + '.'
              + ("0" +(date_en.getMonth()+1)).substr(-2) +
              '.'+ date_en.getFullYear()+' 23:59:59';

          this.sel_a_date = f_dateSt + " - "+ f_dateEn;

          let request_data = new FormData()
          request_data.append('client_id', this.$store.getters['get_user_id'])
          request_data.append('st_date', f_dateSt)
          request_data.append('en_date', f_dateEn)

          this.$http.post('/API/get_report', request_data, {
            headers:
                {'token': this.$store.getters['get_token']},
            responseType: 'blob'
          }).then(response => {
            //  console.log(response)
            var blob = new Blob([response.data], {type: 'application/force-download'})
            const link = document.createElement('a')
            link.href = URL.createObjectURL(blob)
            link.download = 'download.xlsx';
            link.click()
            URL.revokeObjectURL(link.href)
          }).catch(err => {
            // what now?
            console.log(err)

          })
        },

        get_orders(client_id)
        {
          console.log(client_id)
          this.select_client_id = this.$store.getters['get_user_id']
          var date_st = new Date(this.sel_date[0]);
          var date_en = new Date(this.sel_date[1]);

          let f_dateSt = ("0" +date_st.getDate()).substr(-2)  + '.'
              + ("0" +(date_st.getMonth()+1)).substr(-2) +
              '.'+ date_st.getFullYear()+' 00:00:00';

          let f_dateEn= ("0" +date_en.getDate()).substr(-2)  + '.'
              + ("0" +(date_en.getMonth()+1)).substr(-2) +
              '.'+ date_en.getFullYear()+' 23:59:59';

          this.sel_a_date = f_dateSt + " - "+ f_dateEn;

          this.table.loading = true
          let request_data = new FormData()
         // console.log(this.sel_date)
          request_data.append('client_id', this.$store.getters['get_user_id'])
          request_data.append('st_date', f_dateSt)
          request_data.append('en_date', f_dateEn)

          this.$http.post('/API/get_orders', request_data, {
          headers:
          {'token': this.$store.getters['get_token']}
        }).then(response => {

          //  console.log(response.data.data)
            this.table.items = response.data.data
            this.table.loading = false
          }).catch(err => {
            // what now?
            console.log(err)
            //let status = err.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error', 0)
            this.table.loading = false
          })
        },
          set_delete_dialog(item)
          {
              this.dialog.delete_dialog = true
           //   console.log(item)
              this.selected_item = item
          },
        set_delete()
        {
          this.dialog.delete_dialog = false
          let id = this.selected_item.id_detail
          this.$http.delete('/API/delete/order_element/'+id, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {
          //  console.log(response.data.data)
            if(response.data.success == true){
              this.table.items = this.table.items.filter( item => item.id_detail !== id)
            }

          }).catch(err => {
            // what now?
            console.log(err)
            // let status = err.response.status
            //let message = err.message
            // let data_message = err.response.data.message
            // this.$waveui.notify(data_message, 'error', 0)

            // if(status == 401) { router.push('login') }
          })

        },
        change_date(){
          this.get_orders(this.$store.getters['get_user_id'])
        }
      }
}
</script>

<style scoped>
table{

  border-spacing: 0px; /*расстояние между ячейками*/
  border-collapse: collapse;/*  предотвращает задвоение рамок*/
}

.w-table__cell {
  border: 1px solid  black;
}
</style>



