<template>
  <div id="app">
    <header></header>
    <main class="grow">
      <w-card>
        <w-alert success plain>Ваш Заказ сохранен, Ожидаите поступление.....</w-alert>
      </w-card>

    </main>
    <footer></footer>
  </div>
</template>

<script>
import {useStore} from "vuex";

export default {
name: "MessageSaved",
  mounted() {
    const store = useStore()

    setTimeout(() => {
      store.commit('logout')
     this.$router.push('login')
    }, 3000);
  }
}
</script>

<style scoped>

</style>