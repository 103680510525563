<template>

<div>

  <w-toolbar shadow>
    <div class="title2">
      <img src="./../assets/logo32.png">
      sshoreca - {{this.$store.getters['get_user_name']}}</div>
    <div class="spacer"></div>

    <span v-if="this.$store.getters.get_role_id==1" class="ml2"><router-link to="/orders">
      <w-button class="ma1" bg-color="primary" shadow
                tooltip="Главная!"
                :tooltip-props="{ left: true, transition: 'twist', bgColor: 'success' }"
                icon="mdi mdi-home-circle"
      >Заказы</w-button></router-link></span>


    <span v-if="this.$store.getters.get_role_id==2" class="ml2">
      <router-link to="/orderclient">
        <w-button class="ma1" bg-color="primary" shadow
                  tooltip="Сделать Заказ"
                  :tooltip-props="{ left: true, transition: 'twist', bgColor: 'success' }"
                  icon="mdi mdi-account-cash" >

        </w-button></router-link></span>

    <span v-if="this.$store.getters.get_role_id==2" class="ml2">
      <router-link to="/my-order">
        <w-button class="ma1" bg-color="primary" shadow
                  tooltip="Мои Заказы"
                  :tooltip-props="{ left: true, transition: 'twist', bgColor: 'success' }"
                  icon="mdi mdi-account-file-text" >

        </w-button></router-link></span>



    <span v-if="this.$store.getters.get_role_id==1" class="ml2">



     <w-menu>
                <template #activator="{ on }">
                    <w-button v-on="on" class="mr3"
                              icon="mdi mdi-account-credit-card"
                              tooltip="Справочники"
                              :tooltip-props="{ left: true, transition: 'twist', bgColor: 'success' }"
                    >

                    </w-button>
                </template>


       <w-list :items="items" nav color="primary">
  <template #item="{ item }">
    <span>{{ item.label }}</span>
    <div class="spacer"></div>
    <w-icon md>{{ item.icon }}</w-icon>
  </template>
</w-list>

            </w-menu>


    </span>




    <span class="ml2">

            <w-menu>
                <template #activator="{ on }">
                    <w-button v-on="on" class="mr3"   icon="mdi mdi-account">
                    </w-button>
                </template>
                <a href="#" @click.prevent="logout">Выход</a>
            </w-menu>
                </span>

  </w-toolbar>



</div>

</template>

<script>

import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import '@mdi/font/css/materialdesignicons.min.css'

export default {

  setup(){
    const router = useRouter()
    const store = useStore()
    return {
      items: [
        { label: 'Номенклатура', id: 'star', icon: 'mdi mdi-archive-edit-outline', route: '/nomenclature' },
        { label: 'Пользователи', id: 'check', icon: 'mdi mdi-account-box-multiple-outline', route: '/users' },
        { label: 'Группы', id: 'check', icon: 'mdi mdi-animation-outline', route: '/groups' },
        { label: 'Ед.Измерения', id: 'check', icon: 'mdi mdi-animation-outline', route: '/units' }
           ],
      logout: () => {
          store.commit('logout')
          router.push('login')
       }
    }


  }
}
</script>

<style scoped>

</style>