

<template>
  <div id="app">



    <w-dialog
        v-model="dialog.edit_dialog"
        title="Редактирование элемента"
        persistent
        :width="550">

      <w-input
          class="mb4"
          v-model="selected_item.name"
          label="Найменование">
      </w-input>

      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.edit_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="save()"
            bg-color="success">
          сохранить
        </w-button>
      </template>
    </w-dialog>



    <w-dialog
        v-model="dialog.delete_dialog"
        title="Удаление элемента"
        persistent
        :width="550">
      Вы уверены что хотите удалить этот элемент.
      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.delete_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="set_delete()"
            bg-color="success">
          удалить
        </w-button>
      </template>
    </w-dialog>

    <main class="grow">

      <w-button class="ma1" bg-color="success" @click="set_new_dialog">
        <w-icon class="mr1">mdi mdi-plus-box</w-icon>
        Добавить
      </w-button>

      <w-input
          v-model="table.keyword"
          placeholder="Введите текст для поиска..."
          inner-icon-left="wi-search"
          class="mb3">
      </w-input>

      <w-table
          :headers="table.headers"
          :items="table_items"
          fixed-headers
          fixed-footer
          :filter="table.keywordFilter(table.keyword)">
        style="max-height: 500px">
        <template #item-cell="{label, header, item}">


          <span v-if="header.key == 'action'">
                        <w-button class="ma1" bg-color="error" icon="mdi mdi-delete-forever" @click="set_delete_dialog(item)"></w-button>
                        <w-button class="ma1 mr6" bg-color="success" icon="mdi mdi-content-save-edit-outline" @click="set_edit_dialog(item)"></w-button>
                    </span>

          <p v-else>{{label}}</p>

        </template>

        <template #loading>
          Загрузка.....
        </template>
        <template #no-data>
          Нету данных
        </template>


      </w-table>
    </main>
  </div>
</template>

<script>

import {useRouter} from "vue-router";
import '@mdi/font/css/materialdesignicons.min.css'



const router = useRouter()
export default {
  name: "UnitsVue",
  mounted: function(){
    this.get_units()
  },
  data: () => {
    return ({
      dialog: { delete_dialog: false, edit_dialog: false },
      selected_item: {name: ''},
      default_foto: `https://kartinkof.club/uploads/posts/2022-09/1662230744_1-kartinkof-club-p-novie-i-krasivie-kartinki-pomidor-1.jpg`,
      items_select: [
        { label: 'Администратор', role_id: 1 },
        { label: 'Клиент', role_id: 2 }
      ],
      table_items: [{id:1}, {id:2}],
      table: {
        headers: [
          { label: 'ID', key: 'id_unit', width: 2 },
          { label: 'Найменование', key: 'name' },
          { label: '#', key: 'action' }

        ],
        keyword: '',
        keywordFilter: keyword => item => {
          // Concatenate all the columns into a single string for a faster lookup.
          const allTheColumns = `${item.id_unit} ${item.name}`

          // Lookup the keyword variable in the string with case-insensitive flag.
          return new RegExp(keyword, 'i').test(allTheColumns)
        }
      }
    })
  },
    computed:  {
      paginationTable: function(){
          return {
          itemsPerPage: 20,
          total: 10
      }}
    },
  methods:
      {
        get_units()
        {

          this.$http.get('/API/get_units', {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

          //  console.log(response.data.data)
            this.table_items = response.data.data
          }).catch(err => {
            // what now?
            console.log(err)
            let status = err.response.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { router.push('login') }
          })
        },

        set_new_dialog()
        {
          this.dialog.edit_dialog = true
          this.selected_item = {name: ''}
        },
        set_edit_dialog(item)
        {
          this.dialog.edit_dialog = true
          this.selected_item = item
         // console.log(item)
        },
        set_delete_dialog(item)
        {
          this.dialog.delete_dialog = true
        //  console.log(item)
          this.selected_item = item
        },

        set_delete()
        {
          this.dialog.delete_dialog = false
          let id = this.selected_item.id_unit
          let request_data = new FormData()
          request_data.append('id', id)
          this.$http.delete('/API/delete/unit/'+id,{
              headers:
                  {'token': this.$store.getters['get_token']}
          }).then(response => {
          //  console.log(response.data.data)

              if(response.data.success)
              {
                this.table_items = this.table_items.filter( item => item.id_unit !== id)
                this.dialog.delete_dialog = false;
                this.$waveui.notify('Удалено', 'warning')
                this.get_units()
              }

          })

        },

         save()
         {
             let request_data = new FormData()
             request_data.append('item', JSON.stringify(this.selected_item))
             this.$http.post('/API/save/unit', this.selected_item, {
                 headers:
                     {'token': this.$store.getters['get_token']}
             }).then(response => {
             //    console.log(response.data.data)

                 if(response.data.success)
                 {
                     if (typeof this.selected_item['id_unit'] == "undefined")
                     {
                         this.table_items.push(response.data.data)
                     }
                     this.dialog.edit_dialog = false;
                     this.$waveui.notify('Сохранено', 'success')
                     this.get_units()
                 }


             })
         }
      }

}
</script>

<style scoped>

</style>