import { createApp } from 'vue'


import router from './router'

import WaveUI from 'wave-ui'
import 'wave-ui/dist/wave-ui.css'
import App from './App.vue'

import axios from 'axios'
import VueAxios from 'vue-axios'

import print from 'vue3-print-nb'

import Vuex from 'vuex'

import store from './store/index'




const app = createApp(App).use(router);



app.config.compilerOptions.isCustomElement = tag => tag === ['vue-datepicker'].includes(tag)

app.use(store)
app.use(WaveUI)
app.use(VueAxios, axios)
app.use(Vuex)
app.use(print)
//app.component('VueDatePicker', VueDatePicker);
/*
app.config.compilerOptions.isCustomElement = (tag) => {
    console.log(tag)
    return tag.startsWith('vue-')
}*/

//axios.defaults.baseURL = 'http://Orders'
//axios.defaults.baseURL = 'http://f0846778.xsph.ru/Orders'
axios.defaults.baseURL = 'https://sshoreca.kz/Orders'


//axios.defaults.headers.common['token'] = store.state.token
axios.defaults.mode = 'cors'


// Добавляем перехват запросов
axios.interceptors.request.use(function (config) {
    // Здесь можете сделать что-нибудь с перед отправкой запроса
    return config;
}, function (error) {
    // Сделайте что-нибудь с ошибкой запроса
    console.log(error)
    return Promise.reject(error);
});


app.use(store)

app.mount('#app')

// Добавляем перехват ответов
axios.interceptors.response.use(function (response) {
    // Любой код состояния, находящийся в диапазоне 2xx, вызывает срабатывание этой функции
    // Здесь можете сделать что-нибудь с ответом

    return response;
}, function (error) {
    // Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции
    // Здесь можете сделать что-то с ошибкой ответа
     let status = error.response.status
    //let message = err.message
   // let data_message = error.response.data.message

      if(status == 401) { router.push('login') }

    return Promise.reject(error);
});
