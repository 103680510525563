<template>
<nav-bar-header></nav-bar-header>
</template>

<script>

import navBarHeader from '../components/navBar'

export default {
  name: "mainLayout",
  components: {navBarHeader}
}
</script>

<style scoped>

</style>