import { createRouter, createWebHistory } from 'vue-router'
import Orders from '../views/OrdersVue'
import NomenclatureView from '../views/Nomenclature'
import OrderClient from '../views/OrderClient'
import Groups from '../views/Groups'
import UsersVue from '../views/Users'
import LoginView from '../views/Login'
import Units from '../views/Units'
import store from '../store/index'
import Saved from '../views/MessageSaved'
import MyOrder from "@/views/MyOrder";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Orders,
      meta: {
        layout: 'main',
          auth: true
      }
  },
    {
        path: '/login',
        name: 'login',
        component: LoginView,
        meta: {
            layout: 'login'
        }
    },
    {
        path: '/saved',
        name: 'saved',
        component: Saved,
        meta: {
            layout: 'login'
        }
    },
    {
        path: '/units',
        name: 'units',
        component: Units,
        meta: {
            layout: 'main',
            auth: true
        }
    },
    {
        path: '/groups',
        name: 'groups',
        component: Groups,
        meta: {
            layout: 'main',
            auth: true
        }
    },
    {
        path: '/users',
        name: 'users',
        component: UsersVue,
        meta: {
            layout: 'main',
            auth: true
        }
    },
    {
        path: '/my-order',
        name: 'MyOrder',
        component: MyOrder,
        meta: {
            layout: 'main',
            auth: true
        }
    },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
      meta: {
          layout: 'main',
          auth: true
      }
  },
    {
      path: '/orders',
      name: 'orders',
      component: Orders,
        meta: {
            layout: 'main',
            auth: true
        }
    },
    {
        path: '/nomenclature',
        name: 'nomenclature',
        component: NomenclatureView,
        meta: {
            layout: 'main',
            auth: true
        }
    },
    {
        path: '/orderclient',
        name: 'orderclient',
        component: OrderClient,
        meta: {
            layout: 'main',
            auth: true
        }
    }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach(
    (to, from, next)=>{
    const requireAuth = to.meta.auth

        if(requireAuth && store.getters['is_authentification'])
        {
            next()
        }
        else if (requireAuth && !store.getters['is_authentification'])
        {
            next('/login?message=auth')
        }
        else
        {next()}
})

export default router
