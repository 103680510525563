

<template>
  <div id="app">

    <w-overlay
        v-model="this.showOverlay"
        bg-color="rgba(35, 71, 129, 0.4)">
      <w-alert bg-color="pink" color="white">
        Идет сохранение.......
      </w-alert>
    </w-overlay>

    <w-dialog
        v-model="dialog.confirm"
        title="Подтвердите заказ"
        persistent
        :width="550">

      <div style=" max-height: 70vh;   overflow-y: auto;">
      <w-table
          :headers="this.table.headers"
          :items="this.saves_items"
          fixed-headers
          fixed-footer>

        <template #item-cell="{label, header, item}">

          <w-tag v-if="header.key == 'unit'"
                 class="ma1"
                 color="primary"
                 lg>
            {{label}}
          </w-tag>

          <w-image v-else-if="header.key == 'foto'"
                   class="mr0"
                   :src="this.$store.getters.get_url_image+ label"
                   :width="32"
                   :height="32">
          </w-image>

          <span v-else-if="header.key == 'action'">{{item.order_count}}</span>

          <p v-else>{{label}}</p>

        </template>

        <template #loading>
          Загрузка.....
        </template>
        <template #no-data>
          Не выбраны продукты
        </template>

      </w-table>
      </div>
      <template #actions>
        <div class="spacer" />
        <w-button
            lg
            :disabled="this.disabled_save"
            @click="save()"
            bg-color="success">
          Подтвердить
        </w-button>
        <w-button
            lg
            class="mr2"
            @click="dialog.confirm = false"
            bg-color="error">
          Отмена
        </w-button>
      </template>
    </w-dialog>


    <main class="grow pt3">

      <w-button lg class="ma1" bg-color="success" @click="show_dialog_conform()">
        <w-icon class="mr1">mdi mdi-plus-box</w-icon>
        Сохранить заказ
      </w-button>
      <w-input
          v-model="table.keyword"
          placeholder="Введите текст для поиска..."
          inner-icon-left="wi-search"
          class="mb3 pt3">
      </w-input>
      <w-table
          :headers="table.headers"
          :items="table.items"
          fixed-headers
          fixed-footer
          :filter="table.keywordFilter(table.keyword)">
        <template #item-cell="{label, header, item}">

          <w-tag v-if="header.key == 'unit'"
                 class="ma1"
                 color="primary"
                 lg>
            {{label}}
          </w-tag>

          <w-image v-else-if="header.key == 'foto'"
                   class="mr0"
                   :src="this.$store.getters.get_url_image+ label"
                   :width="32"
                   :height="32">
          </w-image>

          <span v-else-if="header.key == 'action'">
                            <w-flex align-center>
                  <w-button
                      @click="set_change_val(item, -1, true)"
                      icon="wi-minus"
                      bg-color="success"
                      lg>
                  </w-button>


                    <w-confirm  left align-top class="ma2" :cancel="false" confirm="ok" @confirm="set_change_val(item, item.order_count, false)">
{{item.order_count}}
  <template #question>
   <w-input
       class="mb1 col-1"
       type="number"
       label="Введите значение"
       outline
   @change="set_change_val(item, item.order_count, false)"
   v-model="item.order_count">
</w-input>
  </template>
</w-confirm>



                  <w-button
                      @click="set_change_val(item, 1, true)"
                      icon="wi-plus"
                      bg-color="success"
                      lg>
                  </w-button>
                </w-flex>
                    </span>

          <p v-else>{{label}}</p>

        </template>

          <template #loading>
              Загрузка.....
          </template>
          <template #no-data>
              Нету данных
          </template>


      </w-table>
    </main>
    <w-button class="ma7" lg  bg-color="success" style="margin-bottom: 50px; margin-top: 20px" @click="show_dialog_conform">
      <w-icon class="mr1">mdi mdi-plus-box</w-icon>
      Сохранить заказ
    </w-button>
  </div>
</template>

<script>

import {toRaw} from "@vue/reactivity";

export default {
  name: "OrderClientView",
  mounted: function(){
    this.get_nomenclature()
  },
  data: () => {
    return ({
      showOverlay: false,
      dialog: { delete_dialog: false, edit_dialog: false, confirm: false },
      selected_item: {},
      deleted_item: [],
      disabled_save: false,
      default_foto: `https://kartinkof.club/uploads/posts/2022-09/1662230744_1-kartinkof-club-p-novie-i-krasivie-kartinki-pomidor-1.jpg`,
      table: {
        headers: [
          { label: '#', key: 'foto', width: 2 },
          { label: 'Найменование', key: 'name', width: 12 },
          { label: 'ед.', key: 'unit_name', width: 1 },
          { label: 'к-во', key: 'action', width: 4 }

        ],
        items: [ ],
        keyword: '',
        keywordFilter: keyword => item => {
          // Concatenate all the columns into a single string for a faster lookup.
          const allTheColumns = `${item.id_nom} ${item.name}`

          // Lookup the keyword variable in the string with case-insensitive flag.
          return new RegExp(keyword, 'i').test(allTheColumns)
        },
        pagination: {
          itemsPerPage: 50,
          total: 200
        }
      },
      saves_items: [ ],
    })
  },
  methods:
      {

        get_orders()
        {
          this.select_client_id = this.$store.getters['get_user_id']
          var date_st = new Date();
          var date_en = new Date();

          let f_dateSt = ("0" +date_st.getDate()).substr(-2)  + '.'
              + ("0" +(date_st.getMonth()+1)).substr(-2) +
              '.'+ date_st.getFullYear()+' 00:00:00';

          let f_dateEn= ("0" +date_en.getDate()).substr(-2)  + '.'
              + ("0" +(date_en.getMonth()+1)).substr(-2) +
              '.'+ date_en.getFullYear()+' 23:59:59';

          this.sel_a_date = f_dateSt + " - "+ f_dateEn;

          let request_data = new FormData()
          // console.log(this.sel_date)
          request_data.append('client_id', this.$store.getters['get_user_id'])
          request_data.append('st_date', f_dateSt)
          request_data.append('en_date', f_dateEn)

          this.$http.post('/API/get_orders', request_data, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

            let dataOrder = response.data.data
            let tableItems = this.table.items

            let filteredElements = dataOrder.map(function(item){

             let elem =  tableItems.filter(function(titem){
                if(item.nomenclature_id == titem.id_nom) {
                  titem.amount = item.ammount
                  titem.id_detail = item.id_detail
                  return toRaw(titem)
                }
                })
              return toRaw(elem[0])
              }, tableItems)



            this.saves_items = toRaw(filteredElements);


            this.table.items =  tableItems.map(function(tableitem){

              let elem =  filteredElements.filter(function(fitem){
                if(fitem.id_nom == tableitem.id_nom) {

                  tableitem.order_count = parseFloat(fitem.amount)
                  tableitem.id_detail = parseInt(fitem.id_detail)
                  return tableitem
                }
              })

             console.log(elem[0])

              if ( elem.length>0)  {  return toRaw(elem[0])}
              else
              return toRaw(tableitem)

            }, filteredElements)



          }).catch(err => {
            // what now?
            console.log(err)
            try {
              let data_message = err.response.data.message
              this.$waveui.notify(data_message, 'error', 0)
              this.table.loading = false
            }catch (e) {
              this.table.loading = false
            }
          })
        },

        get_nomenclature()
        {
          let request_data = new FormData()

          this.$http.post('/API/get_nomenclature', request_data, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

          //  console.log(response.data.data)
            this.table.items = response.data.data

            this.get_orders()

          }).catch(err => {
            // what now?
             let  data_message = "Ошибка";
              let status = 0;

              if(err.response.status !=="undefined" && err.response.status!==null)
              {
                  status = err.response.status
                  //let message = err.message
                  data_message = err.response.data.message
              }
              else
              {
                  data_message = err;
              }

            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },
        show_dialog_conform() {
          this.dialog.confirm = true
        },
          save()
          {
            this.dialog.confirm = false
            this.disabled_save = true
            this.showOverlay = true
            //  let request_data = new FormData()
            let new_data = this.saves_items;
            let delete_data = this.deleted_item;

            if(delete_data)
            this.$http.post('/API/delete/orderclient', delete_data, {
              headers:
                  {'token': this.$store.getters['get_token']}
            }).then( () =>{  this.deleted_item.splice(1, this.deleted_item.length) })

              //request_data.append('item', JSON.stringify(this.selected_item))
              this.$http.post('/API/save/orderclient', new_data, {
                  headers:
                      {'token': this.$store.getters['get_token']}
              }).then(response => {
               //   console.log(response.data.data)

                  if(response.data.success)
                  {
                        this.$waveui.notify('Сохранено', 'success')
                    setTimeout(() => this.$router.push('my-order'), 1650);
                    this.disabled_save = false

                  }
                  else
                  {
                    this.showOverlay = false
                    this.$waveui.notify('Не сохранено', 'warning')
                    this.disabled_save = false
                  }

              }).catch(function (error) {
            this.showOverlay = false
            this.$waveui.notify(error.response.data, 'warning')
                this.disabled_save = false
          })
          },

        set_change_val(item, go, is_step)
        {
          let step = parseFloat(item.step);

          if (is_step) {
            if((parseFloat(item.order_count) +  step*parseInt(go)>=0)||(isNaN(item.order_count))) {
              item.order_count = isNaN(item.order_count) ? (step * parseInt(go)).toFixed(2) : (parseFloat(item.order_count) + step * parseInt(go)).toFixed(2)
            } else item.order_count = 0
            item.amount =  parseFloat(item.order_count)
          }
          else
          {
            item.order_count = parseInt(go)
            item.amount =  item.order_count
          }


          let idx =  this.saves_items.findIndex(el => parseInt(el.id_nom) == parseInt(item.id_nom))

          if (item.order_count>0) {
            if (idx > -1)
              this.saves_items[idx] = item
            else
            this.saves_items.push(item)
          }
          else if ((idx !== undefined)&&(idx > -1)) {
          //  console.log('slice idx '+ idx)
            if('id_detail' in item)
            this.deleted_item.push(item)

            this.saves_items.splice(idx, 1)
            item.order_count =0
          }

         // console.log('saves_item',this.saves_items)
         // console.log('deleted_item', this.deleted_item)


        }
      },
  watch: {

  }

}
</script>

<style scoped>
.w-overlay {backdrop-filter: blur(10px);}
</style>