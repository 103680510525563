<template>

  <div>
    <component :is="layout+'Layout'" v-if="layout"></component>
    <router-view/>
  </div>


</template>
<script>

import mainLayout from "@/layout/mainLayout";
import loginLayout from "@/layout/loginLayout";
import {computed} from "vue";
import {useRoute} from "vue-router";

    export default {
        name: 'App',
      components: {mainLayout, loginLayout},
      setup(){
          const route = useRoute()
        console.log(route.meta)

        return {
            layout: computed( () => {return route.meta.layout})
        }

      }
    }

</script>


<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
