

<template>
  <div id="app">
    <w-dialog
        v-model="dialog.edit_dialog"
        title="Редактирование элемента"
        persistent
        :width="550">

      <w-flex wrap class="text-center">
      <div class="xs6 pa1">
        <w-input
            class="mb4"
            outline
            v-model="this.selected_item.name"
            label="Найменование">
        </w-input>
      </div>

      <div class="xs6 pa1">
  <w-select v-model="this.selected_item.unit" class="mb4" outline label="Ед.изм" :items="this.get_units_computed"></w-select>
      </div>
      </w-flex>

      <w-flex wrap class="text-center">
        <div class="xs4 pa1">
          <w-input
              class="mb4"
              outline
              type="number" min="0.1" max="1000"
              v-model="this.selected_item.step"
              label="Шаг измерения">
          </w-input>
        </div>

        <div class="xs4 pa1">
          <w-select v-model="this.selected_item.group" class="mb4" outline label="Группы" :items="this.get_group_computed"></w-select>
        </div>

        <div class="xs4 pa1">
          <w-input
              class="mb4"
              outline
              type="number" min="1" max="999999999"
              v-model="this.selected_item.sort_id"
              label="Позиция в списке">
          </w-input>
        </div>
      </w-flex>



      <w-flex wrap class="text-center">
        <div class="xs6 pa1">
          <w-select v-model="this.selected_item.print_header" class="mb4" outline label="Печатать заголовок перед номенклатурой?" :items="this.dialog_da_net"></w-select>
        </div>

        <div class="xs6 pa1">
          <w-select v-model="this.selected_item.other_col" class="mb4" outline label="Выводить отдельной колонкой?" :items="this.dialog_da_net"></w-select>
        </div>
      </w-flex>


      <w-flex wrap class="text-center">
        <div class="xs6 pa1">
          <w-select v-model="this.selected_item.visible" class="mb4" outline label="Показывать в списке заказа" :items="this.dialog_da_net"></w-select>
          </div>

        <div class="xs6 pa1">
          <w-input
              class="mb4"
              outline
              type="number" min="0" max="999999999"
              v-model="this.selected_item.cost"
              label="Стоимость в Праис">
          </w-input>

        </div>
      </w-flex>



      <w-card  class="mb4">
        <w-grid columns="12">
          <w-grid columns="6" class="wrapper">
            <w-image class="mr5"
                     v-model="this.selected_item.foto"
                     :src="default_foto"
                     :width="150"
                     :height="150">
            </w-image>
          </w-grid>

          <w-grid columns="6" >

            <input type="file" id="file" ref="file" v-on:change="handleFileUpload()" style="width: 150px; height: 150px;"/>


          </w-grid>

        </w-grid>

      </w-card>



      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.edit_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="save()"
            bg-color="success">
          сохранить
        </w-button>
      </template>
    </w-dialog>



    <w-dialog
        v-model="dialog.delete_dialog"
        title="Удаление элемента"
        persistent
        :width="550">
      Вы уверены что хотите удалить этот элемент.
      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.delete_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="set_delete()"
            bg-color="success">
          удалить
        </w-button>
      </template>
    </w-dialog>


    <w-dialog
        v-model="dialog.load_price_dialog"
        title="Загрузка Прайс листа из Excel"
        persistent
        :width="550">

      <div style="padding-bottom: 10px">
      <input type="file" id="file_price" ref="file_price" v-on:change="onLoadFileUploadPrice()" />
      </div>

      <div style=" max-height: 70vh;   overflow-y: auto;">
        <w-table
            :headers="this.table.headers_loadprice"
            :items="this.load_price_result"
            fixed-headers
            fixed-footer>

          <template #item-cell="{label, header}">

            <w-tag v-if="header.key == 'status'"
                   class="ma1"
                   color="primary"
                   lg>
              {{label}}
            </w-tag>

            <p v-else>{{label}}</p>

          </template>

          <template #loading>
            Загрузка.....
          </template>
          <template #no-data>
          Загрузите праис для Результата
          </template>

        </w-table>
      </div>

      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.load_price_dialog = false"
            bg-color="error">
          закрыть
        </w-button>
        <w-button
            @click="parse_price_excel()"
            bg-color="success">
          загрузить
        </w-button>
      </template>
    </w-dialog>

    <main class="grow">
      <w-button class="ma1" bg-color="success" @click="set_new_dialog">
        <w-icon class="mr1">mdi mdi-plus-box</w-icon>
        Добавить
      </w-button>

      <w-switch v-model="this.priceItem" class="ma4" label="Праис лист" color="success">
        <template #thumb>
          <small>{{ this.priceItem ? 'Да' : 'Нет' }}</small>
        </template>
      </w-switch>

      <w-button class="ma1" bg-color="warning" @click="this.dialog.load_price_dialog = true">
        <w-icon class="mr1">mdi mdi-plus-box</w-icon>
        Загрузить Прайс
      </w-button>

      <w-input
          v-model="table.keyword"
          placeholder="Введите текст для поиска..."
          inner-icon-left="wi-search"
          class="mb3">
      </w-input>

      <w-table
          :headers="table.headers"
          :items="table.items"
          fixed-headers
          fixed-footer
          :filter="table.keywordFilter(table.keyword)">
        style="max-height: 500px">
        <template #item-cell="{label, header, item}">

          <w-tag v-if="header.key == 'unit'"
                 class="ma1"
                 color="primary"
                 lg>
            {{label}}
          </w-tag>

          <w-image v-else-if="header.key == 'foto'"
                   class="mr5"
                   :src="this.$store.getters.get_url_image+ label"
                   :width="32"
                   :height="32">
          </w-image>

          <span v-else-if="header.key == 'action'">
                        <w-button class="ma1" bg-color="error" icon="mdi mdi-delete-forever" @click="set_delete_dialog(item)"></w-button>
                        <w-button class="ma1 mr6" bg-color="success" icon="mdi mdi-content-save-edit-outline" @click="set_edit_dialog(item)"></w-button>
                    </span>

          <p v-else>{{label}}</p>

        </template>

          <template #loading>
              Загрузка.....
          </template>
          <template #no-data>
              Нету данных
          </template>


      </w-table>
    </main>
  </div>
</template>

<script>

export default {
  name: "NomenclatureView",
  mounted: function(){
    this.get_nomenclature()
    this.get_units()
    this.get_groups()
    this.get_params()
  },
  data: () => {
    return ({
      dialog: { delete_dialog: false, edit_dialog: false, load_price_dialog: false },
      selected_item: {},
      load_img: false,
      load_price: false,
      default_price_file: '',
      default_foto: `https://kartinkof.club/uploads/posts/2022-09/1662230744_1-kartinkof-club-p-novie-i-krasivie-kartinki-pomidor-1.jpg`,
      units_sel: [
        { label: 'шт' },
        { label: 'уп' },
        { label: 'ед' },
      ],
      price_list : true,
      dialog_da_net: [{label: 'Да', code: 1}, {label: 'Нет', code: 0}],
      groups_sel: [],
      load_price_result: [],
      table: {
        headers: [
          { label: 'ID', key: 'sort_id', width: 2 },
          { label: 'Фото', key: 'foto', width: 4 },
          { label: 'Найменование', key: 'name' },
          { label: 'ед.изм', key: 'unit_name', width: 4 },
          { label: 'шаг', key: 'step', width: 4 },
          { label: '#', key: 'action', width: 4 }

        ],
        headers_loadprice: [
          { label: 'Продукт в excel', key: 'new_name', width: 2},
          { label: 'Продукт в БД', key: 'old_name', width: 2},
          { label: 'Цена в excel', key: 'old_price', width: 2},
          { label: 'Цена в бд', key: 'new_price', width: 2},
          { label: 'Результат', key: 'status', width: 2},
        ],
        items: [ ],
        keyword: '',
        keywordFilter: keyword => item => {
          // Concatenate all the columns into a single string for a faster lookup.
          const allTheColumns = `${item.id_nom} ${item.name}`

          // Lookup the keyword variable in the string with case-insensitive flag.
          return new RegExp(keyword, 'i').test(allTheColumns)
        },
        pagination: {
          itemsPerPage: 50,
          total: 200
        }
      }
    })
  },
  computed:{
    priceItem: {
      get(){
        return this.price_list },
      set(val) {
        this.price_list = val
         this.set_params(val)
         }
    },
    get_units_computed : function(){
       return this.units_sel.map((item) => {
         return {'label': item.name, 'code': item.id_unit}
       })
    },
    get_group_computed : function(){
      return this.groups_sel.map((item) => {
        return {'label': item.group_name, 'code': item.id_group , "group": item}
      })
    },
  },
  methods:
      {
        onLoadFileUploadPrice()
        {
          this.file_price = this.$refs.file_price.files[0];

          let reader  = new FileReader();

          reader.addEventListener("load", function () {
            // this.showPreview = true;
            this.default_price_file = reader.result;
          }.bind(this), false);
          if( this.file_price ){
            if ( /\.(xls|xlsx)$/i.test( this.file_price.name ) ) {
              reader.readAsDataURL( this.file_price );
              this.load_price = true
            }
          }


          //  console.log(this.file)
        },

        handleFileUpload(){
          this.file = this.$refs.file.files[0];

          let reader  = new FileReader();

          reader.addEventListener("load", function () {
            // this.showPreview = true;
            this.default_foto = reader.result;
          }.bind(this), false);
          if( this.file ){
            if ( /\.(jpe?g|png|gif)$/i.test( this.file.name ) ) {
              reader.readAsDataURL( this.file );
              this.load_img = true
            }
          }


        //  console.log(this.file)
        },

        get_groups()
        {

          this.$http.get('/API/get_groups', {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

            //  console.log(response.data.data)
            this.groups_sel = response.data.data
          }).catch(err => {
            // what now?
            console.log(err)
            let status = err.response.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },

        get_units()
        {

          this.$http.get('/API/get_units', {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

          //  console.log(response.data.data)
            this.units_sel = response.data.data
          }).catch(err => {
            // what now?
            console.log(err)
            let status = err.response.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },


        get_params()
        {

          this.$http.get('/API/get_params', {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

            let params = response.data.data
            this.price_list =  parseInt(params[0]['param_bool'])===1 ? true : false;
            return parseInt(params[0]['param_bool'])===1 ? true : false;
          }).catch(err => {
            // what now?
            console.log(err)
            let status = err.response.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },

        set_params(param)
        {
         // let request_data = new FormData()

          let params = {}
          params.param = param
          this.$http.post('/API/set_params', params, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

            console.log(response.data.data)
           // let d = response.data.data
          }).catch(err => {
            // what now?
            let  data_message = "Ошибка";
            let status = 0;

            if(err.response.status !=="undefined" && err.response.status!==null)
            {
              status = err.response.status
              //let message = err.message
              data_message = err.response.data.message
            }
            else
            {
              data_message = err;
            }

            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },



        get_nomenclature()
        {
          let request_data = new FormData()

          this.$http.post('/API/get_nomenclature', request_data, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

            this.table.items = response.data.data
          }).catch(err => {
            // what now?
             let  data_message = "Ошибка";
              let status = 0;

              if(err.response.status !=="undefined" && err.response.status!==null)
              {
                  status = err.response.status
                  //let message = err.message
                  data_message = err.response.data.message
              }
              else
              {
                  data_message = err;
              }

            this.$waveui.notify(data_message, 'error', 0)

            if(status == 401) { this.$router.push('login') }
          })
        },
        set_new_dialog()
        {
          // {'label': item.group_name, 'code': item.id_group , "group": item}
          this.dialog.edit_dialog = true
          this.selected_item = {name: '', step: 1, sort_id: 1,
            unit: 'кг',
            group: 'не указано',
            print_header : 'Нет',
            other_col: 'Нет'}
        },
        set_edit_dialog(item)
        {


          this.dialog.edit_dialog = true
           // item.foto = this.$store.getters.get_url_image+item.foto
          item.unit = {'label': item.unit_name, 'code': item.unit_id}
          item.group = {'label': item.group_name, 'code': item.group_id}

          item.other_col = {'label': item.other_col == 1 ? 'Да' : 'Нет', 'code': item.other_col}
          item.print_header = {'label': item.print_header == 1 ? 'Да' : 'Нет', 'code': item.print_header}
          item.visible = {'label': item.visible == 1 ? 'Да' : 'Нет', 'code': item.visible}


          this.selected_item = item
          this.default_foto = this.$store.getters.get_url_image + item.foto
        },

          parse_price_excel()
          {
            this.load_price_result = [];

            let new_data = new Object()
            if(this.load_price){
              new_data.blob = this.default_price_file

              this.$http.post('/API/price/load', new_data, {
                headers:
                    {'token': this.$store.getters['get_token']}
              }).then(response => {

                if(response.data.success)
                {
                  this.load_price_result = response.data.data
                  //this.dialog.load_price_dialog = false;
                  this.$waveui.notify('Прайс загружен', 'success')
                  this.load_price = false
                } else
                  this.$waveui.notify('Неверный праис лист', 'error')


              })

            }
          },

          save()
          {

            let new_data = this.selected_item
            if(this.load_img){
            new_data.blob = this.default_foto

            }


            if(typeof this.selected_item.visible==='object')
            {
              new_data.visible = this.selected_item.visible.label
            }

            if(typeof this.selected_item.other_col==='object')
            {
              new_data.other_col = this.selected_item.other_col.label
            }

            if(typeof this.selected_item.print_header==='object')
            {
              new_data.print_header = this.selected_item.print_header.label
            }


            if(typeof this.selected_item.group==='object')
            {
              new_data.group_id = this.selected_item.group.code
              new_data.group_name = this.selected_item.group.label
            }
            else {

              let group = this.groups_sel.find((elem) => elem.group_name == this.selected_item.group)
              if(group) {
                new_data.group_id = group.id_group
                new_data.group_name = group.group_name
              } else
              {new_data.group_id =0; new_data.group_name = "не указан"; }
            }

            if(typeof this.selected_item.unit==='object')
            {
              new_data.unit_id = this.selected_item.unit.code
              new_data.unit_name = this.selected_item.unit.label
            }
            else {

              let unit = this.units_sel.find((elem) => elem.name == this.selected_item.unit)
             // console.log(unit)
              new_data.unit_id = unit.id_unit
              new_data.unit_name = unit.name
            }

           // console.log(new_data)
           // console.log(JSON.stringify(this.selected_item))

              //request_data.append('item', JSON.stringify(this.selected_item))
              this.$http.post('/API/save/nomenclature', new_data, {
                  headers:
                      {'token': this.$store.getters['get_token']}
              }).then(response => {
                //  console.log(response.data.data)

                  if(response.data.success)
                  {
                      if (typeof this.selected_item['id_nom'] == "undefined")
                      {
                          this.table.items.push(response.data.data)
                      }
                      this.dialog.edit_dialog = false;
                      this.$waveui.notify('Сохранено', 'success')
                      this.load_img = false
                      this.get_nomenclature()
                  }


              })
          },
          set_delete_dialog(item)
        {
          this.dialog.delete_dialog = true
         // console.log(item)
          this.selected_item = item
        },

        set_delete()
        {
          this.dialog.delete_dialog = false
          let id = this.selected_item.id_nom
          this.$http.delete('/API/delete/nomenclature/'+id, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {
           // console.log(response.data.data)
            if(response.data.success == true){
              this.table.items = this.table.items.filter( item => item.id_nom !== id)
              this.get_nomenclature()
            }

          }).catch(err => {
              // what now?
              console.log(err)
             // let status = err.response.status
              //let message = err.message
             // let data_message = err.response.data.message
             // this.$waveui.notify(data_message, 'error', 0)

             // if(status == 401) { router.push('login') }
          })

        }
      }

}
</script>

<style scoped>

</style>