

<template>
  <div id="app">



    <w-dialog
        v-model="dialog.edit_dialog"
        title="Редактирование элемента"
        persistent
        :width="550">

      <w-input
          class="mb4"
         v-model="this.selected_item.fio"
          label="ФИО пользователя">
      </w-input>

      <w-input
          class="mb4"
          type="password"
          v-model="this.selected_item.pwd"
          label="Пароль пользователя">
      </w-input>

      <w-input
          class="mb4"
          v-model="this.selected_item.address"
          label="Адрес местоположения">
      </w-input>

      <w-select
          v-model="this.selected_item.role_id"
          class="mb4" label="Роль в системе" :items="items_select"></w-select>




      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.edit_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="save()"
            bg-color="success">
          сохранить
        </w-button>
      </template>
    </w-dialog>



    <w-dialog
        v-model="dialog.delete_dialog"
        title="Удаление элемента"
        persistent
        :width="550">
      Вы уверены что хотите удалить этот элемент.
      <template #actions>
        <div class="spacer" />
        <w-button
            class="mr2"
            @click="dialog.delete_dialog = false"
            bg-color="error">
          отмена
        </w-button>
        <w-button
            @click="set_delete()"
            bg-color="success">
          удалить
        </w-button>
      </template>
    </w-dialog>

    <main class="grow">
      <w-button class="ma1" bg-color="success" @click="set_new_dialog">
        <w-icon class="mr1">mdi mdi-plus-box</w-icon>
        Добавить
      </w-button>
      <w-input
          v-model="table.keyword"
          placeholder="Введите текст для поиска..."
          inner-icon-left="wi-search"
          class="mb3">
      </w-input>

      <w-table
          :headers="table.headers"
          :items="table.items"
          fixed-headers
          fixed-footer
          :filter="table.keywordFilter(table.keyword)">
        style="max-height: 500px">
        <template #item-cell="{label, header, item}">

          <span v-if="header.key == 'action'">
                        <w-button class="ma1" bg-color="error" icon="mdi mdi-delete-forever" @click="set_delete_dialog(item)"></w-button>
                        <w-button class="ma1 mr6" bg-color="success" icon="mdi mdi-content-save-edit-outline" @click="set_edit_dialog(item)"></w-button>
                    </span>

          <p v-else>{{label}}</p>

        </template>

        <template #loading>
          Загрузка.....
        </template>
        <template #no-data>
          Нету данных
        </template>


      </w-table>
    </main>
  </div>
</template>

<script>

import {useRouter} from "vue-router";
const router = useRouter()
export default {
  name: "UsersVue",
  mounted: function(){
    this.get_users()
  },
  data: () => {
    return ({
      dialog: { delete_dialog: false, edit_dialog: false },
      selected_item: {},
      default_foto: `https://kartinkof.club/uploads/posts/2022-09/1662230744_1-kartinkof-club-p-novie-i-krasivie-kartinki-pomidor-1.jpg`,
      items_select: [
        { label: 'Администратор', role_id: 1 },
        { label: 'Клиент', role_id: 2 }
      ],
      table: {
        headers: [
          { label: 'ID', key: 'id_user', width: 2 },
          { label: 'Клиент', key: 'fio' },
          { label: 'Адрес', key: 'address'},
          { label: 'Роль', key: 'role_name', width: 2 },
          { label: '#', key: 'action', width: 3 }

        ],
        items: [],
        keyword: '',
        keywordFilter: keyword => item => {
          // Concatenate all the columns into a single string for a faster lookup.
          const allTheColumns = `${item.id_user} ${item.fio} ${item.address}`

          // Lookup the keyword variable in the string with case-insensitive flag.
          return new RegExp(keyword, 'i').test(allTheColumns)
        },
        pagination: {
          itemsPerPage: 50,
          total: 200
        }
      }
    })
  },
  methods:
      {
        get_users()
        {
          let request_data = new FormData()

          this.$http.post('/API/get_users', request_data, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(response => {

          //  console.log(response.data.data)
            this.table.items = response.data.data
          }).catch(err => {
            // what now?
            console.log(err)
            let status = err.response.status
            //let message = err.message
            let data_message = err.response.data.message
            this.$waveui.notify(data_message, 'error')

            if(status == 401) { router.push('login') }
          })
        },
        set_new_dialog()
        {
          this.dialog.edit_dialog = true
          this.selected_item = {name: ''}
        },

        set_edit_dialog(item)
        {
          this.dialog.edit_dialog = true
          this.selected_item = item
         // console.log(item)
        },

          save()
          {
              let request_data = new FormData()
              request_data.append('item', JSON.stringify(this.selected_item))
              this.$http.post('/API/save/user', this.selected_item, {
                  headers:
                      {'token': this.$store.getters['get_token']}
              }).then(response => {
               //   console.log(response.data.data)

                  if(response.data.success)
                  {
                      if (typeof this.selected_item['id_user'] == "undefined")
                      {
                          this.table.items.push(this.selected_item)
                      }
                      this.dialog.edit_dialog = false;
                      this.$waveui.notify('Сохранено', 'success')
                  }


              })
          },
        set_delete_dialog(item)
        {
          this.dialog.delete_dialog = true
        //  console.log(item)
          this.selected_item = item
        },

        set_delete()
        {
          this.dialog.delete_dialog = false
          let id = this.selected_item.id_user
          this.$http.delete('/API/delete/user/'+id, {
            headers:
                {'token': this.$store.getters['get_token']}
          }).then(() => {

            this.table.items = this.table.items.filter( item => item.id_user !== id)

          })

        }
      }

}
</script>

<style scoped>

</style>